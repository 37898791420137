import { useEffect, useRef, useState } from "react";

export default function MinScrollHeightContainer({ className, children }) {
  const [height, setHeight] = useState("");
  const ref = useRef();

  useEffect(() => {
    try {
      if (ref) {
        setHeight(ref.current.scrollHeight + 200);

        window.addEventListener("resize", () =>
          setHeight(ref.current.scrollHeight + 200)
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, [ref]);

  return (
    <div
      ref={ref}
      style={{
        minHeight: height,
        width: "100%",
      }}
      className={className}
    >
      {children}
    </div>
  );
}
