import "./App.css";
import { useEffect, useState } from "react";

import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import Home from "./v2/Home";
import Hiring from "./v2/Hiring";
import Terms from "./v2/Terms";
import Privacy from "./v2/Privacy";

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "terms",
    element: <Terms />,
  },
  {
    path: "privacy",
    element: <Privacy />,
  },
  {
    path: "career",
    element: <Hiring />,
  },
  {
    path: "*",
    element: (
      <div className="notFound">
        <h3>No page found</h3>
        <a href="/">Go back to home</a>
      </div>
    ),
  },
]);

function App() {
  useEffect(() => {
    document.title = "Sowiz";
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
