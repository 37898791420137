import { useEffect, useState } from "react";

export default function ScreenHeight90({ children, className, ratio = 1, id }) {
  const [height, setHeight] = useState("");

  useEffect(() => {
    try {
      if (window) {
        setHeight(window.innerHeight * ratio);
        window.addEventListener("resize", () =>
          setHeight(window.innerHeight * ratio)
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, [ratio]);

  return (
    <div
      id={id}
      className={className}
      style={{
        minHeight: height,
        width: "100%",
      }}
    >
      {children}
    </div>
  );
}
