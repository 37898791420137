import ScreenHeight90 from "../ScreenHeightContainer";
import styles from "./terms.module.scss";
import back from "../icons/back.svg";

export default function PrivacyPolicy() {
  return (
    <ScreenHeight90 ratio={1} className={styles.terms}>
      <div
        onClick={() => {
          window.location.href = "/";
        }}
        className={styles.terms__head}
      >
        <img src={back} />
      </div>
      <div className={styles.terms__back}>
        <img src="/service.png" />
      </div>
      <div className={styles.terms__main}>
        <p className={styles.terms__main__title}>PRIVACY POLICY</p>

        <ul className={styles.terms__main__content}>
          <li>
            We are committed to protecting your privacy and ensuring the
            security of your personal information.
          </li>
          <li>
            This Privacy Policy outlines how we collect, use, and safeguard the
            data you provide to us.
          </li>
          <li>
            When you use our services, we may collect information such as your
            name, contact details, and usage data.
          </li>
          <li>
            This information is used to improve our services, respond to
            inquiries, and enhance your user experience.
          </li>
          <li>
            We do not share your personal information with third parties except
            as necessary to provide our services or as required by law.
          </li>
          <li>
            We implement robust security measures to protect your data from
            unauthorized access, alteration, or disclosure.
          </li>
          <li>
            By using our services, you consent to the collection and use of your
            information in accordance with this Privacy Policy.
          </li>
          <li>
            If you have any questions or concerns about our privacy practices,
            please contact us..
          </li>
        </ul>
      </div>
    </ScreenHeight90>
  );
}
