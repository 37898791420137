import ScreenHeight90 from "../ScreenHeightContainer";
import styles from "./terms.module.scss";
import back from "../icons/back.svg";

export default function Terms() {
  return (
    <ScreenHeight90 ratio={1} className={styles.terms}>
      <div
        onClick={() => {
          window.location.href = "/";
        }}
        className={styles.terms__head}
      >
        <img
          onClick={() => {
            window.location.href = "/";
          }}
          src={back}
        />
      </div>
      <div className={styles.terms__back}>
        <img src="/service.png" />
      </div>
      <div className={styles.terms__main}>
        <p className={styles.terms__main__title}>TERMS AND CONDITIONS</p>

        <ul className={styles.terms__main__content}>
          <li>
            By accessing and using our services, you agree to comply with the
            following terms and conditions.
          </li>

          <li>Our services are provided on an "as-is" basis.</li>

          <li>
            We reserve the right to modify, suspend, or discontinue any aspect
            of the service at any time without notice.
          </li>

          <li>
            You are responsible for ensuring that your use of our services
            complies with all applicable laws and regulations.
          </li>

          <li>
            Any content you upload or share must not infringe on the rights of
            others or contain unlawful material
          </li>

          <li>
            We do not guarantee the accuracy or completeness of any information
            provided through our services and are not liable for any errors or
            omissions.
          </li>

          <li>
            Your use of our services is at your own risk, and we shall not be
            liable for any damages arising from your use of or inability to use
            our services.
          </li>

          <li>
            By continuing to use our services, you acknowledge that you have
            read, understood, and agree to these terms and conditions.
          </li>
        </ul>
      </div>
    </ScreenHeight90>
  );
}
