import styles from "./home.module.scss";
import bannerLeft from "../icons/bannerLeft.svg";
import s1Icon from "../icons/s1.svg";
import s2Icon from "../icons/s2.svg";
import s3Icon from "../icons/s3.svg";
import s4Icon from "../icons/s4.svg";
import { TypeAnimation } from "react-type-animation";
import MinScrollHeightContainer from "../MinScrollHeightDiv";
import { useState } from "react";

export default function HomeV2() {
  const [fn, setFn] = useState("");
  const [email, setEmail] = useState("");
  const [ln, setLn] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const sendMessage = () => {
    fetch("http://localhost:3000/api/contact", {
      method: "POST",
      body: JSON.stringify({
        name: fn + " " + ln,
        email: email,
        comment: message,
      }),
    });
  };

  return (
    <div className={styles.home}>
      <header className={styles.home__header}></header>
      <div className={styles.home__main}>
        <div className={styles.home__main__banner}>
          <img src="/logo192.png" className={styles.home__main__banner__logo} />
          <img
            fetchPriority="high"
            src={"https://assets.dev.swz-infra.net/assets/hero.png"}
            className={styles.home__main__banner__back}
          />

          <section className={styles.home__main__banner__content}>
            <p className={styles.home__main__banner__content__title}>
              Welcome to the Era of 
            </p>
            <p className={styles.home__main__banner__content__subtitle}>
              Actionable{" "}
              <TypeAnimation
                sequence={["Influence", 1000]}
                wrapper="span"
                speed={5}
                style={{}}
                repeat={Infinity}
              />
            </p>
            <p className={styles.home__main__banner__content__desc}>
              Fueled by AI, we empower businesses to convey their best messaging
              and help customers gain insights through AI-driven social wisdom.
              Our personalized, rapid content at scale transforms
              decision-making, enabling quick, informed choices.
            </p>
            <button>Contact us</button>
            <div className={styles.home__main__banner__content__scroll}>
              <img src="/scroll.gif" />
            </div>
          </section>
        </div>
        <MinScrollHeightContainer className={styles.home__main__services}>
          <img
            fetchPriority="low"
            src="https://assets.dev.swz-infra.net/assets/service.png"
            className={styles.home__main__services__back}
          />
          <section className={styles.home__main__services__content}>
            <p className={styles.home__main__services__content__title}>
              Your Business, Our Expertise in Action
            </p>
            <p className={styles.home__main__services__content__subtitle}>
              Delivering Solutions to Achieve{" "}
              <strong>Unprecedented Customer Conversion</strong>
            </p>
            <div className={styles.home__main__services__content__items}>
              <div
                className={styles.home__main__services__content__items__item}
              >
                <header
                  className={
                    styles.home__main__services__content__items__item__header
                  }
                >
                  <img src={s1Icon} />
                  <span>Empowering businesses with B2B2C solutions</span>
                </header>
                <main
                  className={
                    styles.home__main__services__content__items__item__main
                  }
                >
                  Our <strong>B2B2C solution</strong> streamlines communication,
                  accelerating the journey from awareness to decision with a
                  single, configurable platform that boosts{" "}
                  <strong>customer conversion.</strong>
                </main>
              </div>
              <div
                className={styles.home__main__services__content__items__item}
              >
                <header
                  className={
                    styles.home__main__services__content__items__item__header
                  }
                >
                  <img src={s2Icon} />
                  <span>Interactive Video Chatbots for Engaging Support</span>
                </header>
                <main
                  className={
                    styles.home__main__services__content__items__item__main
                  }
                >
                  Enhance engagement with <strong>AI-driven chatbots</strong>{" "}
                  that integrate video for real-time, personalized responses,
                  creating a more
                  <strong> immersive user experience.</strong>
                </main>
              </div>
              <div
                className={styles.home__main__services__content__items__item}
              >
                <header
                  className={
                    styles.home__main__services__content__items__item__header
                  }
                >
                  <img src={s3Icon} />
                  <span>AI-Powered Video Summaries with Social Wisdom</span>
                </header>
                <main
                  className={
                    styles.home__main__services__content__items__item__main
                  }
                >
                  Leverage AI to deliver concise{" "}
                  <strong>video summaries</strong> enriched with
                  <strong> social media insights,</strong> keeping customers
                  informed and saving time for smarter decisions in a fast-paced
                  <strong> digital world.</strong>
                </main>
              </div>
              <div
                className={styles.home__main__services__content__items__item}
              >
                <header
                  className={
                    styles.home__main__services__content__items__item__header
                  }
                >
                  <img src={s4Icon} />
                  <span>Uncovering Hidden Insights with Data-Driven AI</span>
                </header>
                <main
                  className={
                    styles.home__main__services__content__items__item__main
                  }
                >
                  Our AI-driven approach analyzes{" "}
                  <strong>customer behavior</strong> to identify pain points and
                  inefficiencies, delivering hyper-personalized follow-ups and{" "}
                  <strong>actionable strategies.</strong>
                </main>
              </div>
            </div>
          </section>
        </MinScrollHeightContainer>
        <div className={styles.home__main__solutions}>
          <img
            fetchPriority="low"
            src={"https://assets.dev.swz-infra.net/assets/solution.png"}
            className={styles.home__main__solutions__back}
          />

          <section className={styles.home__main__solutions__content}>
            <p className={styles.home__main__solutions__content__title}>
              Tailored Solutions
            </p>
            <p className={styles.home__main__solutions__content__subtitle}>
              Innovative answers for your business challenges.
            </p>
            <div className={styles.home__main__solutions__content__products}>
              <div
                className={
                  styles.home__main__solutions__content__products__product
                }
              >
                <img fetchPriority="low" src="/p1.gif" />
                <div
                  className={
                    styles.home__main__solutions__content__products__product__content
                  }
                >
                  <p
                    className={
                      styles.home__main__solutions__content__products__product__content__title
                    }
                  >
                    AI-Powered Social Wisdom: 
                    <strong>Compiled Video Summaries</strong>
                  </p>
                  <p
                    className={
                      styles.home__main__solutions__content__products__product__content__subtitle
                    }
                  >
                    AI-Powered Social Wisdom: Harness AI-driven Social Wisdom to
                    deliver video summarise that capture key insights from
                    social media trends providing customers with concise and
                    relevant content, saving time and enabling smarter
                    decisions.
                  </p>
                </div>
              </div>
              <div
                className={
                  styles.home__main__solutions__content__products__product2
                }
              >
                <img fetchPriority="low" src="/p2.gif" />
                <div
                  className={
                    styles.home__main__solutions__content__products__product__content
                  }
                >
                  <p
                    className={
                      styles.home__main__solutions__content__products__product__content__title
                    }
                  >
                    Interactive Chatbots with{" "}
                    <strong> videos to answer the question</strong>
                  </p>
                  <p
                    className={
                      styles.home__main__solutions__content__products__product__content__subtitle
                    }
                  >
                    Interactive Chatbots with videos to answer the question: Our
                    interactive chatbots offer effective and personalized
                    interactions. Customers are far more likely to be convinced
                    by well-crafted videos available within the chatbot,
                    offering clear and effective messeging that elevate the
                    overall user experience.
                  </p>
                </div>
              </div>
              <div
                className={
                  styles.home__main__solutions__content__products__product
                }
              >
                <img fetchPriority="low" src="/p3.gif" />
                <div
                  className={
                    styles.home__main__solutions__content__products__product__content
                  }
                >
                  <p
                    className={
                      styles.home__main__solutions__content__products__product__content__title
                    }
                  >
                    Interactive Referral System for
                    <strong>  Building Trust</strong>
                  </p>
                  <p
                    className={
                      styles.home__main__solutions__content__products__product__content__subtitle
                    }
                  >
                    Interactive Referral System for Building Trust: Our
                    AI-powered solution includes an interactive referral system
                    that taps into trusted connections, boosting conversion
                    rates by helping customers find deals within their network.
                  </p>
                </div>
              </div>
              <div
                className={
                  styles.home__main__solutions__content__products__product2
                }
              >
                <img fetchPriority="low" src="/p4.gif" />
                <div
                  className={
                    styles.home__main__solutions__content__products__product__content
                  }
                >
                  <p
                    className={
                      styles.home__main__solutions__content__products__product__content__title
                    }
                  >
                    Uncovering Hidden Insights with{" "}
                    <strong>AI-Powered Data</strong>
                  </p>
                  <p
                    className={
                      styles.home__main__solutions__content__products__product__content__subtitle
                    }
                  >
                    Uncovering Hidden Insights with AI-Powered Data: In today’s
                    complex business landscape, our AI-driven approach
                    identifies customer pain points, uncovers inefficiencies,
                    and extracts meaningful insights. By analyzing customer
                    behavior, we deliver targeted, hyper-personalized follow-ups
                    that transform insights into actionable strategies.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>

        <div className={styles.home__main__contact}>
          {/* <img
            fetchPriority="low"
            src={"https://assets.dev.swz-infra.net/assets/solution.png"}
            className={styles.home__main__contact__back}
          /> */}

          <section className={styles.home__main__contact__content}>
            <p className={styles.home__main__contact__content__title}>
              Contact Us
            </p>
            <p className={styles.home__main__contact__content__subtitle}>
              Contact us for any inquiries or support. We're here to help
            </p>
            <div className={styles.home__main__contact__content__container}>
              <div
                className={
                  styles.home__main__contact__content__container__header
                }
              >
                <img src="/logo192.png" />
              </div>
              <div
                className={
                  styles.home__main__contact__content__container__twoInputs
                }
              >
                <div className={styles.inputContainer}>
                  <label>First Name</label>
                  <input value={fn} onChange={(e) => setFn(e.target.value)} />
                </div>
                <div className={styles.inputContainer}>
                  <label>Last Name</label>
                  <input value={ln} onChange={(e) => setLn(e.target.value)} />
                </div>
              </div>
              <div
                className={
                  styles.home__main__contact__content__container__twoInputs
                }
              >
                <div className={styles.inputContainer}>
                  <label>Email</label>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className={styles.inputContainer}>
                  <label>Phone number</label>
                  <input
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div
                className={
                  styles.home__main__contact__content__container__oneInput
                }
              >
                <div className={styles.inputContainer}>
                  <label>Message</label>
                  <input
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
              </div>
              <div
                className={styles.home__main__contact__content__container__left}
              >
                <button onClick={sendMessage}>Send message</button>
              </div>
            </div>
          </section>
        </div>

        <div className={styles.home__footer}>
          <div className={styles.home__footer__title}>
            <img src="/logo192.png" />
            <span>
              Transforming Business with AI-Powered Growth and Engagement
            </span>
            <div></div>
          </div>
          <div className={styles.home__footer__content}>
            <section className={styles.home__footer__content__item}>
              <p
                onClick={() => {
                  window.location.href = "/";
                }}
                className={styles.home__footer__content__item__title}
              >
                Company
              </p>
              <p
                onClick={() => {
                  window.location.href = "/career";
                }}
                className={styles.home__footer__content__item__desc}
              >
                Careers
              </p>
              {/* <p className={styles.home__footer__content__item__desc}>
                Integration
              </p> */}
              {/* <p className={styles.home__footer__content__item__desc}>Blog</p> */}
            </section>
            <section className={styles.home__footer__content__item}>
              <p className={styles.home__footer__content__item__title}>Legal</p>
              <p
                onClick={() => {
                  window.location.href = "/terms";
                }}
                className={styles.home__footer__content__item__desc}
              >
                Terms & Conditions
              </p>
              <p
                onClick={() => {
                  window.location.href = "/privacy";
                }}
                className={styles.home__footer__content__item__desc}
              >
                Privacy Policy
              </p>
              <p className={styles.home__footer__content__item__desc}>
                Contact Us
              </p>
            </section>
            <section className={styles.home__footer__content__item}>
              <p className={styles.home__footer__content__item__desc}>
                © 2024 All rights reserved
              </p>
            </section>
            <section className={styles.home__footer__content__item}></section>
          </div>
        </div>
      </div>
    </div>
  );
}
